<template>
  <!-- My 강의실:코치 -->
  <coach-my-page-content-layout user-classes-coach>
    UserClassesCoach
  </coach-my-page-content-layout>
</template>

<script>
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout';

export default {
  name: 'UserClassesCoach',
  components: { CoachMyPageContentLayout },
};
</script>
